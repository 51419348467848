<template>
  <v-main>
    <v-container
      fluid
      class="full-height overflow-x-hidden overflow-y-auto no-scrollbar pa-0"
    >
      <v-row no-gutters class="mb-8 mt-5">
        <v-col class="px-1">
          <go-back></go-back>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-8 mt-5 ">
        <v-col cols="4" class="pa-0">
          <region-selection-small class="px-3"> </region-selection-small>
        </v-col>
      </v-row>
      <v-row no-gutters class="px-3">
        <v-col cols="12" class="py-0">
          <span class="text-h6" style="color: #173466">
            {{ $t("inSitu.resultView.viewTitle") }}
          </span>
        </v-col>
        <v-col cols="12" class="pt-2">
          <span class="text-caption" style="color: #173466">
            {{ $t("inSitu.resultView.viewSubtitle") }}
          </span>
        </v-col>
      </v-row>
      <v-row no-gutters class="px-3">
        <v-col class="py-0">
          <span
            v-if="
              !this.allregionsVirtualStations.length &&
                this.dataForResultView.length
            "
            >{{ $t("inSitu.resultView.drawStationsHint")
            }}<v-btn
              text
              elevation="0"
              color="primary"
              :to="{
                name: 'MapView',
                params: { regionId: this.regionId }
              }"
              >{{ $t("inSitu.resultView.drawStation") }}
            </v-btn>
          </span>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="regionIsLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <div v-if="vectorLayer.length > 0">
        <v-row
          no-gutters
          v-for="dataset in dataForResultView"
          :key="dataset.dataSetID"
        >
          <v-layout>
            <v-flex>
              <v-col>
                <v-card class="elevation-0" style="background-color:#f6f7fb">
                  <v-card-subtitle class="pa-0 text-caption">
                    {{ $t("inSitu.resultView.datasetSubtitle") }}
                    <span v-if="!dataset.dataForContent[0].layerOwner">
                      - {{ $t("inSitu.resultView.ownerDescription") }}
                    </span>
                  </v-card-subtitle>
                  <v-card-title class="dataset_title text-body-1 pa-0">
                    {{ dataset.dataSetName }}
                    <v-spacer></v-spacer>
                    <v-icon
                      @click="deleteDataSet(dataset)"
                      v-if="
                        !dataset.dataForContent.length ||
                          (dataset.dataForContent.length &&
                            dataset.dataForContent[0].layerOwner)
                      "
                      >mdi-delete</v-icon
                    >
                  </v-card-title>
                  <div
                    id="divHeader"
                    ref="divHeader"
                    style="overflow-x: auto; white-space: nowrap; background-color: #f6f7fb; margin-bottom: -8px"
                  >
                    <div
                      v-for="parentHeader in tableHeaders(dataset)
                        .parentHeaders"
                      :key="parentHeader.value"
                      class="custom-header-cell"
                      :style="dynamicStyle(parentHeader)"
                    >
                      {{ parentHeader.text }}
                    </div>
                  </div>
                  <v-data-table
                    ref="dataTable"
                    :headers="tableHeaders(dataset).headers"
                    :items="prepareTableData(dataset)"
                    hide-default-footer
                    style="table-layout: fixed; width: 100%"
                    class="custom-scrollbar elevation-0"
                  >
                    <template v-slot:item="{ item, index }">
                      <tr
                        class="elevation-1"
                        style="background-color: #FFFFFF; padding: 16px 24px; height: 60px"
                      >
                        <td
                          v-for="(header, headerIndex) in tableHeaders(dataset)
                            .headers"
                          :key="header.value"
                          :class="{
                            'right-border': isLastAssociated(
                              header,
                              headerIndex,
                              tableHeaders(dataset).headers
                            )
                          }"
                        >
                          <template v-if="isAssociated(header, item)">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  class="d-flex flex-row justify-center align-center pr-4"
                                  color="#34944F"
                                  v-on="on"
                                  >mdi-check-circle-outline</v-icon
                                >
                              </template>
                              <span>
                                {{ $t("inSitu.resultView.hintGreenCheck") }}
                              </span>
                            </v-tooltip>
                          </template>
                          <template v-else-if="isNotAssociated(header, item)">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  class="d-flex flex-row justify-center align-center pr-4"
                                  color="#8FD14F"
                                  v-on="on"
                                  >mdi-check</v-icon
                                >
                              </template>
                              <span>
                                {{ $t("inSitu.resultView.hintGreenCheck") }}
                              </span>
                            </v-tooltip>
                          </template>
                          <template v-else-if="isValueZero(header, item)">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  color="#F6F9F9"
                                  class="d-flex flex-row justify-center align-center pr-4"
                                  v-on="on"
                                >
                                  mdi-checkbox-blank-circle-outline
                                </v-icon>
                              </template>
                              <span>
                                {{ $t("inSitu.resultView.hintGreyCircle") }}
                              </span>
                            </v-tooltip>
                          </template>
                          <template
                            v-else-if="
                              header.text ===
                                $t('inSitu.headers.correspondingStation')
                            "
                          >
                            <v-autocomplete
                              class="ma-0"
                              rounded
                              :disabled="!dataset.dataForContent[0].layerOwner"
                              :items="allregionsVirtualStations"
                              v-model="item.associatedStationName"
                              @change="
                                updateAssociatedVirtualStation(
                                  $event,
                                  prepareTableData(dataset)[index]
                                    .inSituStationID,
                                  dataset.dataSetID
                                )
                              "
                              item-text="name"
                              return-object
                              :placeholder="$t('inSitu.headers.notAssociated')"
                            ></v-autocomplete>
                          </template>
                          <template v-else>
                            {{ item[header.value] }}
                          </template>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-flex>
          </v-layout>
        </v-row>
      </div>
      <v-row v-else no-gutters class="mb-8 mt-5 px-2" style="height: 60vh">
        <empty-state-no-in-situ-data></empty-state-no-in-situ-data>
      </v-row>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title
            >{{ $t("inSitu.resultView.deleteConfirmation") }}
            <v-spacer></v-spacer>
            {{ dataSetToDeleteName }} ?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="red" text @click="deleteItemConfirm">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import GoBack from "@/core/components/download/GoBack";
import { mapActions, mapGetters, mapState } from "vuex";
import inSituDataMixin from "@/core/mixins/inSituData.mixin";
import virtualStationMixin from "@/core/mixins/virtualStation.mixin";
import axios from "axios";
import RegionSelectionSmall from "@/core/components/menu/RegionSelectionSmall";
import RegionMixins from "@/core/mixins/region.mixin";
import EmptyStateNoInSituData from "@/core/components/EmptyStates/EmptyStateNoInSituData";

export default {
  components: {
    EmptyStateNoInSituData,
    RegionSelectionSmall,
    GoBack
  },
  mixins: [inSituDataMixin, virtualStationMixin, RegionMixins],

  data() {
    return {
      allregionsVirtualStations: [],
      dialogDelete: false,
      dataSetToDeleteName: "",
      regionIsLoading: true,
      hasInitialized: false
    };
  },
  computed: {
    ...mapGetters("management", ["activeRegion"]),
    ...mapState("vector", ["vectorLayer"]),
    ...mapState("inSitu", [
      "inSituStations",
      "dataForResultView",
      "dataForResultViewHeaders"
    ]),
    regionId() {
      return this.$route.params.regionId;
    }
  },
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    ...mapActions("vector", ["fetchVectorLayer"]),
    ...mapActions("inSitu", [
      "clearDataForResultView",
      "clearDataForResultViewHeaders"
    ]),
    ...mapActions("app", ["showSnackbar"]),

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    tableHeaders(dataset) {
      const headerMap = {};

      // associated headers
      this.dataForResultViewHeaders.associatedHeaders.forEach(header => {
        const headerKey = header.headerName;
        headerMap[headerKey] = {
          text: headerKey.toUpperCase().replace("ASSOCIATED", ""),
          value: headerKey,
          category: "ASSOCIATED",
          width: "150",
          align: "center"
        };
      });

      // exclusions
      const predefinedExclusions = new Set([
        "associatedStationID",
        "associatedStationName",
        "inSituStationName",
        "inSituStationID"
      ]);

      // custom headers from the dataset
      dataset.result.forEach(content => {
        Object.keys(content).forEach(key => {
          const matchingObject = this.dataForResultViewHeaders.notAssociatedHeaders.find(
            obj => obj.headerName === key
          );
          if (
            !predefinedExclusions.has(key) &&
            !headerMap[key] &&
            matchingObject
          ) {
            headerMap[key] = {
              text: this.capitalizeFirstLetter(
                key.replace("NON-ASSOCIATED", "")
              ),
              value: key,
              category: "NOT ASSOCIATED",
              width: "150",
              align: "center"
            };
          }
        });
      });

      // static headers
      const predefinedHeaders = [
        {
          text: this.$t("inSitu.headers.name"),
          value: "inSituStationName",
          category: "GENERAL",
          width: "200",
          sortable: true,
          align: "left"
        },
        {
          text: this.$t("inSitu.headers.correspondingStation"),
          value: "associatedStationName",
          category: "GENERAL",
          width: "200",
          align: "left"
        }
      ];

      const cheatedHeader = [
        {
          text: "",
          value: ""
        }
      ];
      // Combine all headers (keep unique)
      const combinedHeaders = Object.values(headerMap);
      let associatedCount = 0,
        generalCount = 0;

      // Count headers under each category
      combinedHeaders.forEach(header => {
        if (header.category === "ASSOCIATED") {
          associatedCount++;
        }
        if (header.category === "NOT ASSOCIATED") {
          generalCount++;
        }
      });

      // Calculate widths
      let assoWidth = associatedCount * (associatedCount === 1 ? 300 : 150);
      let nonAssoWidth = generalCount * (generalCount === 1 ? 300 : 150);

      const parentHeaders = [
        {
          text: "\u00A0",
          value: "first",
          width: "400"
        }
      ];

      if (associatedCount > 0) {
        parentHeaders.push({
          text: this.$t("inSitu.headers.associated"),
          width: `${assoWidth}`
        });
      }

      if (generalCount > 0) {
        parentHeaders.push({
          text: this.$t("inSitu.headers.nonAssociated"),
          width: `${nonAssoWidth}`
        });
      }
      // Update widths if count is 1
      if (associatedCount === 1) {
        combinedHeaders.forEach(header => {
          if (header.category === "ASSOCIATED") {
            header.width = "300";
          }
        });
      }

      if (generalCount === 1) {
        combinedHeaders.forEach(header => {
          if (header.category === "NOT ASSOCIATED") {
            header.width = "300";
          }
        });
      }

      parentHeaders.push({
        text: "",
        value: ""
      });
      return {
        headers: [...predefinedHeaders, ...combinedHeaders, ...cheatedHeader],
        parentHeaders
      };
    },

    prepareTableData(dataset) {
      let tableData = [];
      dataset.dataForContent.forEach(content => {
        let result = dataset.result.find(
          r => r.inSituStationID === content.inSituStationID
        );
        if (result) {
          const { ...otherResultFields } = result;

          tableData.push({
            inSituStationName: content.inSituStationName,
            associatedStationName: content.associatedStationName,
            inSituStationID: content.inSituStationID,
            ...otherResultFields
          });
        }
      });
      tableData.sort((a, b) => a.inSituStationID - b.inSituStationID);

      return tableData;
    },
    isLastAssociated(header, index, headers) {
      return (
        header.category === "ASSOCIATED" &&
        headers.slice(index + 1).every(h => h.category !== "ASSOCIATED")
      );
    },
    isLastHeaderAssociated(headers) {
      const lastHeader = headers.findLast(
        header => header.category === "ASSOCIATED"
      );
      return lastHeader ? lastHeader.value : null;
    },

    isAssociated(header, item) {
      if (header.category === "ASSOCIATED" && item[header.value] > 0) {
        return true;
      }
    },
    isNotAssociated(header, item) {
      if (header.category === "NOT ASSOCIATED" && item[header.value] > 0) {
        return true;
      }
    },
    isValueZero(header, item) {
      return item[header.value] === 0;
    },
    deleteDataSet(item) {
      this.dialogDelete = true;

      this.dataSetToDeleteName = item.dataSetName;
      this.dataSetToDeleteID = item.dataSetID;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      await axios.delete(
        `/vector/${this.$appConfig.keycloakClient}/region/${this.activeRegion.id}/layers/${this.dataSetToDeleteID}`
      );
      this.fetchVectorLayer(this.activeRegion.id).then(async () => {
        await this.fetchInSituStationsAndAssociations();
      });
      this.dialogDelete = false;
    },
    async updateAssociatedVirtualStation(selectedStation, issID, layerID) {
      const data = {
        virtual_station: selectedStation.id
      };

      try {
        await axios.patch(
          `/vector/${this.$appConfig.keycloakClient}/region/${this.activeRegion.id}/layers/${layerID}/insitu-stations/${issID}`,
          data
        );
        this.showSnackbar({
          show: true,
          message: this.$t("inSitu.resultView.VSChangeConformation"),
          color: "success"
        });
      } catch (error) {
        this.showSnackbar({
          show: true,
          message: this.$t("inSitu.resultView.VSChangeError", error),
          color: "error"
        });
      }
    },
    dynamicStyle(parentHeader) {
      let style = {};

      if (parentHeader.width) {
        style = {
          maxWidth: parentHeader.width + "px",
          minWidth: parentHeader.width + "px",
          background: "#f6f7fb"
        };
      }

      if (parentHeader.value === "first") {
        style = {
          ...style,
          position: "sticky",
          left: "0",
          zIndex: 1,
          background: "#f6f7fb"
        };
      }

      return style;
    },
    addScrollListener() {
      if (Array.isArray(this.$refs.dataTable)) {
        this.$refs.dataTable.forEach((tableInstance, index) => {
          const overflowElement = tableInstance.$el.querySelector(
            ".v-data-table__wrapper"
          );
          if (overflowElement) {
            overflowElement.addEventListener("scroll", event =>
              this.syncScroll(event, index)
            );
          }
        });
      }
    },

    syncScroll(event, tableIndex) {
      const divHeaders = Array.isArray(this.$refs.divHeader)
        ? this.$refs.divHeader
        : [this.$refs.divHeader];
      const divHeader = divHeaders[tableIndex];

      if (divHeader && event.target) {
        divHeader.scrollLeft = event.target.scrollLeft;
      }
    },
    initializeComponent() {
      this.getOrFetchAccessToken().then(() => {
        this.fetchVectorLayer(this.activeRegion.id).then(() => {
          this.fetchInSituStationsAndAssociations().then(() => {
            this.addScrollListener();
            this.regionIsLoading = false;
          });
          this.fetchVirtualStationsByRegion(this.activeRegion.id).then(
            response => {
              this.allregionsVirtualStations = response;
              this.allregionsVirtualStations.push({
                id: "",
                name: this.$t("inSitu.headers.notAssociated")
              });
            }
          );
        });
      });
    }
  },
  watch: {
    activeRegion: {
      handler(newVal) {
        if (newVal !== undefined && !this.hasInitialized) {
          this.initializeComponent();
          this.hasInitialized = true;
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy() {
    this.clearDataForResultViewHeaders();
    this.clearDataForResultView();
  }
};
</script>

<style scoped>
.full-height {
  height: 90vh !important;
}

table > tbody > tr > td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 1;
  background-color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: #173466;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 200px;
  z-index: 1;
  background-color: white;
}

>>> .theme--light.v-data-table .v-data-table-header > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 1;
  background-color: #f6f7fb;
}
>>> .theme--light.v-data-table .v-data-table-header > tr > th:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 200px;
  z-index: 1;
  background-color: #f6f7fb;
}

.custom-header-cell {
  text-align: center;
  display: inline-block;
}
.custom-scrollbar ::-webkit-scrollbar {
  height: 10px;
}
.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #e9eff5;
  border-radius: 8px;
}
#divHeader::-webkit-scrollbar {
  display: none;
}
#divHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #173466;
  padding: 0;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  color: #173466;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: none;
}
>>> .v-data-table > .v-data-table__wrapper > table {
  border-collapse: separate;
  border-spacing: 0 16px;
  background-color: #f6f7fb;
}
>>> .theme--light.v-data-table thead tr:last-child th {
  height: 20px;
}
>>> .v-text-field__details {
  display: none;
}
.dataset_title {
  width: 400px;
  color: #11274d;
  border-bottom: 1px solid #8996ab;
  background-color: #f6f7fb;
}
>>> .v-select.v-text-field input {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #173466;
}
>>> .v-select.v-text-field input::placeholder {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #173466;
}
>>> .v-autocomplete.v-input > .v-input__control > .v-input__slot {
  padding: 0;
}
.right-border {
  position: relative;
}
.right-border::after {
  content: "";
  position: absolute;
  right: 0;
  top: 10%;
  height: 80%;
  width: 2px;
  background-color: #e9eff5;
}
.right-header-border {
  position: relative;
}
.right-header-border::after {
  content: "";
  position: absolute;
  right: 0;
  top: 10%;
  height: 80%;
  width: 2px;
  background-color: #e9eff5;
}
</style>
