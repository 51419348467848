<template>
  <v-container fluid fill-height>
    <v-row style="align-items: center">
      <v-col align="center">
        <v-card
          elevation="0"
          style="background-color: #E9EFF5"
          class="rounded-circle text-center d-flex flex-column align-center justify-center"
          width="300"
          height="300"
          rounded
        >
          <v-icon color="#A3B1C7" size="200">mdi-file-upload-outline</v-icon>
        </v-card>
        <p class="mt-5 text-h5">{{ $t("baseline.almostThere") }}</p>
        <p>
          {{ $t("emptyState.noInSituDataAvailable") }}
        </p>
        <v-btn
          color="#039BE5"
          rounded
          class="white--text font-weight-bold button-text"
          @click="openDialog"
        >
          {{ $t("emptyState.uploadInSituData") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EmptyStateNoInSituData",
  methods: {
    ...mapActions("inSitu", ["openDialog"]),
    openDialog() {
      this.$store.dispatch("inSitu/openDialog");
      this.$router.push({ name: "MapView" });
    }
  }
};
</script>

<style scoped>
.button-text {
  text-transform: unset !important;
}
</style>
