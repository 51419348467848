var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('v-container',{staticClass:"full-height overflow-x-hidden overflow-y-auto no-scrollbar pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-8 mt-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-1"},[_c('go-back')],1)],1),_c('v-row',{staticClass:"mb-8 mt-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[_c('region-selection-small',{staticClass:"px-3"})],1)],1),_c('v-row',{staticClass:"px-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h6",staticStyle:{"color":"#173466"}},[_vm._v(" "+_vm._s(_vm.$t("inSitu.resultView.viewTitle"))+" ")])]),_c('v-col',{staticClass:"pt-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-caption",staticStyle:{"color":"#173466"}},[_vm._v(" "+_vm._s(_vm.$t("inSitu.resultView.viewSubtitle"))+" ")])])],1),_c('v-row',{staticClass:"px-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0"},[(
            !this.allregionsVirtualStations.length &&
              this.dataForResultView.length
          )?_c('span',[_vm._v(_vm._s(_vm.$t("inSitu.resultView.drawStationsHint"))),_c('v-btn',{attrs:{"text":"","elevation":"0","color":"primary","to":{
              name: 'MapView',
              params: { regionId: this.regionId }
            }}},[_vm._v(_vm._s(_vm.$t("inSitu.resultView.drawStation"))+" ")])],1):_vm._e()])],1),(_vm.regionIsLoading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.vectorLayer.length > 0)?_c('div',_vm._l((_vm.dataForResultView),function(dataset){return _c('v-row',{key:dataset.dataSetID,attrs:{"no-gutters":""}},[_c('v-layout',[_c('v-flex',[_c('v-col',[_c('v-card',{staticClass:"elevation-0",staticStyle:{"background-color":"#f6f7fb"}},[_c('v-card-subtitle',{staticClass:"pa-0 text-caption"},[_vm._v(" "+_vm._s(_vm.$t("inSitu.resultView.datasetSubtitle"))+" "),(!dataset.dataForContent[0].layerOwner)?_c('span',[_vm._v(" - "+_vm._s(_vm.$t("inSitu.resultView.ownerDescription"))+" ")]):_vm._e()]),_c('v-card-title',{staticClass:"dataset_title text-body-1 pa-0"},[_vm._v(" "+_vm._s(dataset.dataSetName)+" "),_c('v-spacer'),(
                      !dataset.dataForContent.length ||
                        (dataset.dataForContent.length &&
                          dataset.dataForContent[0].layerOwner)
                    )?_c('v-icon',{on:{"click":function($event){return _vm.deleteDataSet(dataset)}}},[_vm._v("mdi-delete")]):_vm._e()],1),_c('div',{ref:"divHeader",refInFor:true,staticStyle:{"overflow-x":"auto","white-space":"nowrap","background-color":"#f6f7fb","margin-bottom":"-8px"},attrs:{"id":"divHeader"}},_vm._l((_vm.tableHeaders(dataset)
                      .parentHeaders),function(parentHeader){return _c('div',{key:parentHeader.value,staticClass:"custom-header-cell",style:(_vm.dynamicStyle(parentHeader))},[_vm._v(" "+_vm._s(parentHeader.text)+" ")])}),0),_c('v-data-table',{ref:"dataTable",refInFor:true,staticClass:"custom-scrollbar elevation-0",staticStyle:{"table-layout":"fixed","width":"100%"},attrs:{"headers":_vm.tableHeaders(dataset).headers,"items":_vm.prepareTableData(dataset),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',{staticClass:"elevation-1",staticStyle:{"background-color":"#FFFFFF","padding":"16px 24px","height":"60px"}},_vm._l((_vm.tableHeaders(dataset)
                          .headers),function(header,headerIndex){return _c('td',{key:header.value,class:{
                          'right-border': _vm.isLastAssociated(
                            header,
                            headerIndex,
                            _vm.tableHeaders(dataset).headers
                          )
                        }},[(_vm.isAssociated(header, item))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"d-flex flex-row justify-center align-center pr-4",attrs:{"color":"#34944F"}},on),[_vm._v("mdi-check-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("inSitu.resultView.hintGreenCheck"))+" ")])])]:(_vm.isNotAssociated(header, item))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"d-flex flex-row justify-center align-center pr-4",attrs:{"color":"#8FD14F"}},on),[_vm._v("mdi-check")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("inSitu.resultView.hintGreenCheck"))+" ")])])]:(_vm.isValueZero(header, item))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"d-flex flex-row justify-center align-center pr-4",attrs:{"color":"#F6F9F9"}},on),[_vm._v(" mdi-checkbox-blank-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("inSitu.resultView.hintGreyCircle"))+" ")])])]:(
                            header.text ===
                              _vm.$t('inSitu.headers.correspondingStation')
                          )?[_c('v-autocomplete',{staticClass:"ma-0",attrs:{"rounded":"","disabled":!dataset.dataForContent[0].layerOwner,"items":_vm.allregionsVirtualStations,"item-text":"name","return-object":"","placeholder":_vm.$t('inSitu.headers.notAssociated')},on:{"change":function($event){_vm.updateAssociatedVirtualStation(
                                $event,
                                _vm.prepareTableData(dataset)[index]
                                  .inSituStationID,
                                dataset.dataSetID
                              )}},model:{value:(item.associatedStationName),callback:function ($$v) {_vm.$set(item, "associatedStationName", $$v)},expression:"item.associatedStationName"}})]:[_vm._v(" "+_vm._s(item[header.value])+" ")]],2)}),0)]}}],null,true)})],1)],1)],1)],1)],1)}),1):_c('v-row',{staticClass:"mb-8 mt-5 px-2",staticStyle:{"height":"60vh"},attrs:{"no-gutters":""}},[_c('empty-state-no-in-situ-data')],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("inSitu.resultView.deleteConfirmation"))+" "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.dataSetToDeleteName)+" ?")],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Delete")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }